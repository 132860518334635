import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link } from '@material-ui/core';
import {Container, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import imgCP from '../../assets/img/imagemCP.jpg';

import Header from '../../components/header';
import Footer from '../../components/footer';



const useStyles = makeStyles({

    colDois: {
        backgroundColor: '#87cefa',
        color: '#00BFFF',
        "@media (max-width: 500px)": {
            padding: '0px 20px 0px 20px',
        }
    },

    colTres: {
        color: '#00BFFF',
        padding: '50px 0px 0px 50px',
        "@media (max-width: 500px)": {
            padding: '20px 0px 0px 20px'
        }
    },
    imagem: {
        height: 'auto',
        width: '100%',
        "@media (max-width: 500px)": {
            height: 250,
            width: '100%'
        }
    },
    container: {
        padding: '70px 40px 0 40px',
        "@media (max-width: 500px)": {
            fontSize: '20px',
            textAlign: 'center',
            padding: '30px 0 0 0',

        }
    },
    p: {
        color: '#54B5CF',
        fontSize: '35px',
        fontWeight: 800,
        fontFamily: 'Montserrat',
        "@media (max-width: 500px)": {
            fontSize: '18px',
        }
    },
    botaoOn: {

        backgroundColor: '#54B5CF',
        borderColor: '#54B5CF',
        borderRadius: '25px',
        color: '#fff',
        float: 'left',
        fontFamily: 'Roboto',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        height: '50px',
        letterSpacing: '0.085em',
        margin: '30px 0 0 0',
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        width:'90%',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#0facc4',
            color: '#fff',
            border: 0
        },
        "@media (max-width: 500px)": {

            backgroundColor: '#54B5CF',
            fontSize: '18px',
            width: '80%',
            float: 'left',
            margin:'20px 0 0 40px'
            

        }
    },

    textoLink: {
        fontSize: '20px',
        textDecorationLine: 'underline',
        color: '#00BFFF',
        marginTop: '5%',
        '&:hover': {
            color: '#0facc4',
        },
        "@media (max-width: 500px)": {
            fontSize: '15px',
            margin: '30px 20px 100px 20px',
        }
    },
    linkProfissional: {
        display: 'flex',
        "@media (max-width: 500px)": {
            display: 'grid',
        }
    }


});

export default function ConhecaProfissionais() {
    const classes = useStyles();
    return (
        <div style={{ backgroundColor: '#F8F8FA' }}>
            <Header />

            <Container fluid style={{ margin: '0 0 -5% 0' }}>

                <Row>

                    <Grid item sm={6} style={{ padding: '0px' }}>
                        <Image
                            src={imgCP}
                            className={classes.imagem}
                        />
                    </Grid>

                    <Grid item md={6} className={classes.container}>
                        <p className={classes.p}>ENCONTRAR PROFISSIONAL <br />DA ÁREA DE</p>
                        <Grid style={{ marginTop: '5%' }} >
                            <Grid item sm={12} className={classes.linkProfissional} >
                                <Grid item sm={6}><Link color="primary" className={classes.botaoOn} href="/profissionais-cards?category=fonoaudiologia" >FONOAUDIOLOGIA</Link>{''}</Grid>
                                <Grid item sm={6}><Link className={classes.botaoOn} href="/profissionais-cards?category=psicologia" >PSICOLOGIA</Link>{''}</Grid>
                            </Grid>
                            <Grid item sm={12} className={classes.linkProfissional}>
                                <Grid item sm={6}><Link className={classes.botaoOn} href="/profissionais-cards?category=pedagogia" >PEDAGOGIA</Link >{''}</Grid>
                                <Grid item sm={6}><Link className={classes.botaoOn} href="/profissionais-cards?category=fisioterapia" >FISIOTERAPIA</Link>{''}</Grid>
                            </Grid>
                            <Typography className={classes.textoLink}>
                                <Link href="/admin/dashboard/login" >Profissionais cadastrados recebem acesso a cursos e aplicativos Pró-Fono</Link>
                            </Typography>
                        </Grid>

                    </Grid>

                </Row>
            </Container>


            <Footer />


        </div>
    )
}