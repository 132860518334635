import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import WebFont from 'webfontloader';

WebFont.load({
   google: {
     families: ['Roboto:100,200,300,400,700', 'sans-serif', 'Montserrat:100,200,300,400,700,800']
   }
});

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

