/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "../../../components/styles/style.css";
import Form from "react-bootstrap/Form";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../../components/header";
import ImgLogin from "../../../assets/img/imgLogin.jpg";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { userRegister } from "../../../redux/actions/registerActions";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import Footer from "../../../components/footer";
import { useUserContext } from "../../../providers/useUserContext";
import * as ProfessionalsServices from "../../../services/api/ProfessionalsServices";
import Swal from "sweetalert2";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {},
  image: {
    backgroundImage: `url(${ImgLogin})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  form: {
    marginTop: theme.spacing(5),
    width: "90%",
  },
  paper: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(20, 10),
    "@media (max-width: 500px)": {
      padding: "0px 0px 0px 0px",
      margin: theme.spacing(5, 5),
    },
  },
  submit: {
    margin: theme.spacing(5, 0, 5, 0),
    borderRadius: "30px",
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Roboto",
    backgroundColor: "#54B5CF",
    padding: "5px 20px 5px 20px",
    color: "#F8F8FA",
    "&:hover": {
      backgroundColor: "#0facc4",
      textDecoration: "none",
      color: "#fff",
    },
  },
  titulo: {
    color: "#54B5CF",
    fontFamily: "Montserrat",
    fontSize: "35px",
    fontWeight: 800,
    marginBottom: 10,
    "@media (max-width: 500px)": {
      fontSize: "24px",
    },
  },
  textLink: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "18px",
    color: "#54B5CF",
    textDecoration: "underline",
    "&:hover": {
      color: "#0facc4",
    },
  },
  gridLink: {
    display: "flex",
    "@media (max-width: 500px)": {
      display: "grid",
    },
  },
  input: {
    borderWidth: "0 0 1px 0",
    borderRadius: 0,
    padding: 0,
    backgroundColor: "#F8F8FA",
    "&::placeholder": {
      fontSize: 18,
      color: "#C4C4C4",
    },
    " &:focus": {
      backgroundColor: "#F8F8FA",
      boxShadow: "0 0 0px #fff",
      borderColor: "#0facc4",
    },
  },
  link: {
    marginTop: "50px",
    "@media (max-width: 500px)": {
      marginTop: "0",
    },
  },
  textInput: {
    fontSize: 23,
    color: "#54B5CF",
    fontFamily: "Roboto",
    width: 400,
    margin: "20px 0 -5px 0",
  },
}));

export default function Recupera() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles(); 
  const { id } = useParams();
  const { time } = useParams();
  const [version, serVersion] = React.useState("");

  useEffect(() => {
    (async () => {
      const professional = await ProfessionalsServices.getById(id);
      serVersion(professional.data.version);
    })();
  }, [id]);
  return (
    <div style={{ backgroundColor: "#F8F8FA" }}>
      <Header />
      <Grid container component="main" style={{ marginBottom: "-5%" }}>
        <CssBaseline />

        {version == time ? (
          <>
            <Grid
              item
              xs={false}
              sm={4}
              md={6}
              className={classes.image}
            ></Grid>
            <Grid item xs={12} sm={9} md={6} elevation={6} square>
              <div className={classes.paper}>
                <Typography className={classes.titulo}>
                  ALTERAR SENHA
                </Typography>

                <Formik
                  initialValues={{
                    password: "",
                    submit: false,
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string()
                      .max(255)
                      .required("Preencha uma senha válida")
                      .matches(
                        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{6,}$/,
                        "Mínimo de 6 caracteres, obrigatório ao menos uma letra maiuscula, um número e um caracter especial."
                      ),
                    confirmPassword: Yup.string().oneOf(
                      [Yup.ref("password"), null],
                      "As senhas devem corresponder"
                    ),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      let obj = {
                        password: values.password,
                      };

                      await ProfessionalsServices.changePassword(id, obj).then(
                        (map) => {
                          const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: async (toast) => {},
                            willClose: async () => {
                              history.push("/admin/dashboard/login");
                            },
                          });

                          Toast.fire({
                            icon: "success",
                            title: "Alterado com sucesso!",
                          });
                        }
                      );
                    } catch (error) {
                      const message =
                        error.message || "Ocorreu um erro inexperado";

                      setStatus({ success: false });
                      setErrors({ submit: message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    success,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <Form noValidate={true} onSubmit={handleSubmit}>
                      {errors.submit && (
                        <Alert mt={2} mb={1} severity="warning">
                          {errors.submit}
                        </Alert>
                      )}
                      {isSubmitting && (
                        <Alert mt={2} mb={1} severity="success">
                          Alteração realizado com sucesso!
                        </Alert>
                      )}
                      <p className={classes.textInput} style={{ color: touched.password  && values.password && !errors.password  ? 'green' : '' }}>SENHA</p>

                      <TextField
                        type="password"
                        name="password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        helperText={'Mínimo de 6 caracteres, obrigatório ao menos uma letra maiuscula, um número e um caracter especial.'}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.input}
                        fullWidth
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        mt={2}
                        mb={8}
                      />

                      <p className={classes.textInput} style={{ color: touched.confirmPassword  && values.confirmPassword && !errors.confirmPassword  ? 'green' : '' }}>CONFIRMAR SENHA</p>

                      <TextField
                        type="password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.input}
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        fullWidth
                        mt={2}
                        mb={8}
                      />
                      <Grid className={classes.gridLink}>
                        <Grid item md={4}>
                          <Button
                            type="submit"
                            disabled={isSubmitting}
                            className={classes.submit}
                          >
                            Alterar
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </div>
            </Grid>
          </>
        ) : (
          <div style={{ textAlign: "center", width: "100%" }}>
            <h1>LINK EXPIRADO!</h1>
          </div>
        )}
      </Grid>
      <Footer />
    </div>
  );
}
