import axios from 'axios';
import { getCurrentUser } from './AuthService';

const BASE_URL_MAP = { 
  'localhost': `http://localhost:8000`,//"http://localhost:3000",
  'minhafono.com.br': `${window.location.protocol}//pro-fono-backend.herokuapp.com`,
}

const baseURL = BASE_URL_MAP[window.location.hostname];

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  async (config) => {
    const currentUser = await getCurrentUser();

    if (!currentUser) return config;

    config.headers.Authorization = `Bearer ${currentUser.accessToken}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export { api, baseURL };
