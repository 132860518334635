import { forwardRef } from "react";

import "./styles.css";

export const Checkbox = forwardRef((props, ref) => {
  return (
    <label htmlFor={props?.id} className="checkbox-label">
      <input {...props} type="checkbox" ref={ref} />
      <span>{props?.label}</span>
    </label>
  );
});
