import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core"; 
import Button from '@restart/ui/esm/Button';  
import { getCurrentUser }  from "../../services/api/AuthService"; 

import { Link } from 'react-router-dom';
import HeaderVideos from "../../components/headerVideos";
import Footer from "../../components/footer"; 

import * as PlaylistsServices from "../../services/api/PlaylistsServices"; 
 
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({ 
	botaoPrimeiro: {
		marginRight: "40px",
	  backgroundColor: '#54B5CF',
		  borderColor: '#54B5CF',
		  borderRadius: '25px',
		  color: '#fff',
		  float: 'right',
		  fontFamily: 'Roboto',
		  fontSize: '22px',
		  fontStyle: 'bold',
		  fontWeight: 'bold',
		  height: '50px',
		  margin: '40px 0 0 0',
		  padding: '10px 60px 20px 60px',
		  textDecoration: 'none', 
		  '&:hover': {
			  textDecoration: 'none',
			  backgroundColor:'#0facc4',
			  color:'#fff'
		  },
		  '@media (max-width: 500px)': {
			  backgroundColor: '#54B5CF',
			  fontSize: '20px',
			  padding: '10px 20px 0 20px',
			  margin: '10px 0 0 0'
  
		  }
	}, 
  });
 



export default function Playlists() {
	const classes = useStyles();
	const [page, setPage] = React.useState(1);
	const [playlists, setPlaylists] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const history = useHistory();

	React.useEffect(() => {
		(async () => { 
		  	const resp = await PlaylistsServices.findAll();  
			setPlaylists(resp.data);
			setLoading(false)  
 
		})();
	}, [page]);

	return (
		<div className={classes.container}>
			 {
				loading ? (
					<Box sx={{ width: '100%' }} >
						<LinearProgress color={"error"} />
					</Box>
				) : <></>
			}
			    <HeaderVideos />
		
			
			<Grid
				container
				spacing={0}
				direction="row"
				justifyContent="space-around"
				alignItems="flex-start"
				style={{ padding: "20px" }}
			>
			{playlists?.map((value, index) => ( 
				<> 
				<Grid item xs={12}  md={3}>
				<Link to={`/videos?p=${value?.id}`} style={{ textDecoration: "none" }}>
							
						<div style={{ cursor: "pointer", width: "95%", height: "250px", backgroundImage: `url(${value?.thumbnail})`, backgroundSize: "cover", border: "15px solid #9BDBE9", borderRadius: "20px" }}>
						<div style={{ width: "100px", height: "100%", background: "#000000a6", float: "right", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
								<h4 style={{ color: "white", margin: "0px" }}>{value?.count}</h4>
								<img src="https://cdn-icons-png.flaticon.com/512/6398/6398977.png" style={{ width: "35px", filter: "invert(100%)" }} alt="Pró-fono" />
							</div>
							
						</div>
						<small style={{ color: "#3C6C98" }}>{value?.title}</small>
						</Link>  
						<br></br>
						<br></br>
				</Grid></> 
      		))}
			
			</Grid>
			<Button className={classes.botaoPrimeiro} href="/admin/profissional/perfil">VOLTAR</Button> 
		</div>
	);
}
