import {React , useEffect, useState}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { Grid, Modal, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import useStyles from '../../../components/styles/profissional.styles';
import imgAp from '../../../assets/img/imgLogin.jpg'

import Header from '../../../components/header';
import Footer from '../../../components/footer'; 
import MenuPerfil from '../../../components/menuPerfil';

import * as ProfessionalsServices from '../../../services/api/ProfessionalsServices';
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useUserContext } from "../../../providers/useUserContext";
import * as PlansServices from "../../../services/api/PlansServices";


const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

 
export default function Perfil() {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const user = useUserContext();
    const [plan, setPlan] = useState(null);
    const history = useHistory(); 
    const classes = useStyles();
    const {
        subTitulo, subTituloPerfil,  linkPerfil, input, tituloperfil,
        newGrid, menu, perfil, page, page2, textIcon,  imagPerfil,SegCol,

    } = classes;


  const [profile, setProfile] = useState(null);

  useEffect(() => {
    (async () => {
      const resp = await ProfessionalsServices.getProfile(); 
      
      setProfile(resp.data);
    })();
   }, []);
  
   useEffect(() => {
    if(user){
    (async () => {
        setLoading(true);
        const resp = await PlansServices.get();
        setPlan(resp.data[0]);
        setLoading(false);
    })();
    }
}, [user]);



useEffect(() => {
    if (user) {
        (async () => {
            const resp = await ProfessionalsServices.getProfile();
            setProfile(resp.data);
        })();
    }
}, [user]); 

useEffect(() => {
    setTimeout(() => { 
        if (profile) {  
            if (profile?.crfa !== undefined) {
                console.log('fecha')
                handleClose(); 
            } else {
                handleOpen();
            }
        }
      }, 1000); 
}, [user, profile]);
 
    return (
         
        <div style={{ backgroundColor: '#F8F8FA' }}> 
            <Header />
            <div>
            {profile?.crf == undefined && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							ALERTA!
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							Sua conta ainda não está visível na busca de profissionais, termine o seu cadastro para que seus dados sejam disponibilizadas na plataforma.
						</Typography>
						<br></br>
						<a href={'/admin/profissional/profissionalEditar/'+ profile?.id } style={{marginTop: '20px',
		textDecoration: 'none',
		backgroundColor: '#54B5CF',
		color: '#fff',
		borderRadius: '25px',
		padding: '10px 20px',
		fontSize: '18px',
		'&:hover': {
			backgroundColor: '#0facc4',
			color: '#fff'
		}}}>Editar perfil</a> 
					</Box>
				</Modal>
			)}
                <Grid className={newGrid} >
                    <Grid item md={3} className={menu}>
                        <MenuPerfil />
                    </Grid>
                    <Grid item md={9} className={perfil}>
                        <Grid className={page}>
                            <Grid item md={9} xs={7}>
                                <Typography className={tituloperfil}>MEU PERFIL</Typography>
                            </Grid>
                            <Grid item md={3} xs={5}>
                                
                                <Typography className={textIcon} ><EditIcon />
                                    <Link to={'/admin/profissional/profissionalEditar/'+ profile?.id } style={{ color: '#54B5CF'}}>Editar Perfil</Link>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid className={page2}>
                               
                            <Dialog
                            open={profile?.name === null ? true : false } 
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{"Informações pendentes!"}</DialogTitle>
                            <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                            Necessário personalizar o seu perfil e currículo com informações relevantes que destaquem suas qualificações e experiências profissionais.
                            </DialogContentText>
                            </DialogContent>
                            <DialogActions> 
                            <   Button  ><Link to={'/admin/profissional/profissionalEditar/'+ profile?.id }style={{ color: '#54B5CF' }}>Ir para edição</Link></Button>
                            </DialogActions>
                        </Dialog>
                            <Grid item md={3} style={{textAlign: 'center'}} >
                                {profile?.picture ? <Image
                                    className={imagPerfil}
                                    src={profile.picture}
                                    alt="Imagem Curso"
                                    roundedCircle
                                    /> : <Image
                                    className={imagPerfil}
                                    src={imgAp}
                                    alt="Imagem Curso"
                                    roundedCircle
                                    />
                                }
                                 
                                <input
                                    accept="image/*"
                                    className={input}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                />
                               
                              
                            </Grid>
                            
                            <Grid item md={9} className={SegCol}>
                                <Typography className={subTitulo}>{profile?.name}</Typography>
                                <Typography className={subTituloPerfil}>{profile?.formation} - { profile?.crfa}</Typography>
                                <Typography className={subTitulo}
                                style={{paddingTop: '10px'}} >E-MAIL DA CONTA</Typography>
                                <Typography className={subTituloPerfil}>{profile?.email}</Typography>
                                <Typography className={subTitulo}style={{paddingTop: '10px'}} >SENHA</Typography>
                                <Typography className={subTituloPerfil} >****************</Typography >
                                <Typography className={subTitulo} style={{paddingTop: '10px'}}  >EDITAR MEU CURRÍCULO</Typography>
                                
                                <Typography className={linkPerfil}><Link to={'/admin/profissional/profissionalEditar/'+ profile?.id }style={{ color: '#54B5CF' }}>Clique aqui para editar o seu currículo.</Link>
                                    </Typography> 

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    );
}


