import { makeStyles } from "@material-ui/core/styles";
import "../../../node_modules/video-react/dist/video-react.css"; // import css 

const useStyles=makeStyles((theme) => ({
    botao: {
        cursor: 'pointer',
        backgroundColor: '#54B5CF',
        border: 'none', borderRadius: '30px',
        color: '#fff',
        display: 'flex',
        fontFamily: 'Roboto',
        fontSize: '28px',
        fontWeight: 400,
        justifyContent: 'center',
        margin: '20px auto',
        width: '80%',
        '&:hover': {
			backgroundColor:'#0facc4',
			color:'#fff',
			textDecoration: 'none',
        },
        "@media (max-width: 500px)": {
            backgroundColor: '#54B5CF',
            fontSize: '18px',
            margin: '0 auto',
            padding:'10px 20px 10px 20px',
            width: '100%',
        }
    },

    botaoCancel: {
        cursor: 'pointer',
        backgroundColor: '#FF0000',
        border: 'none', borderRadius: '30px',
        color: '#fff',
        display: 'flex',
        fontFamily: 'Roboto',
        fontSize: '28px',
        fontWeight: 400,
        justifyContent: 'center',
        margin: '20px auto',
        width: '80%',
        '&:hover': {
			backgroundColor:'#FF0000',
			color:'#fff',
			textDecoration: 'none',
        },
        "@media (max-width: 500px)": {
            backgroundColor: '#FF0000',
            fontSize: '18px',
            margin: '0 auto',
            padding:'10px 20px 10px 20px',
            width: '100%',
        }
    },

    colUm: {
        padding: '50px 50px 0px 50px',
        textAlign: 'center',
        "@media (max-width: 500px)": {
            padding: '0 65px 0 65px',
        }
    },

    colDois: {
        backgroundColor: '#fff',
        borderRadius: '23px',
        boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
        padding: '50px',
        "@media (max-width: 500px)": {
            padding: '30px 20px 30px 20px',
            margin: '0 4px 0 4px'
        }
    },
    colTres: {
        padding: '50px 0px 0px 50px',
        "@media (max-width: 500px)": {
            padding: '20px 20px 30px 20px',
        }
    },

    linha: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderWidth: '0px 1px 0px 0px',
        borderColor: '#C4C4C4',
        borderStyle: 'solid',
        padding: '0 0 0 4%',
        "@media (max-width: 500px)": {
            borderWidth: '0px 0px 1px 0px',
            borderColor: '#C4C4C4',
            borderStyle: 'solid',
        }
    },

    p: {
        color: '#54B5CF',
        fontSize: '37px',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        padding: '50px 0px 0px 0px',
        "@media (max-width: 500px)": {
        fontSize: '20px',
        textAlign: 'center'
        }
    },

    p2: {
        fontFamily: 'Montserrat',
        color: '#666666',
        fontSize: '20px',
        padding: '20px 0px 0px 0px',
    },

    p3: {
        fontSize: '51px',
        color: '#C4C4C4',
        fontFamily: 'Montserrat',
        fontWeight: 400,
        "@media (max-width: 500px)": {
            fontSize: '25px',
            textAlign: 'center'
        }

    },

    p4: {
        fontSize: '85px',
        color: '#54B5CF',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        margin: '-50px 0px 0px 0px',
        "@media (max-width: 500px)": {
            fontSize: '45px',
            margin: '-30px 0px 0px 0px',
            textAlign: 'center'
        }
    },

    p5: {
        color: '#666666',
        fontSize: '20px',
        fontFamily: 'Roboto',
        "@media (max-width: 500px)": {
            fontSize: '15px',
            margin: '0 10px 0 10px'
        }
    },

    p6: {
        fontSize: '30px',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        color: '#54B5CF',
        textAlign: 'center',
        "@media (max-width: 500px)": {
            fontSize: '18px',
            margin: '0 0 20px 0 '
        }
    },
    imagem: {
        height: '600px',
        width: '100%',
        objectFit: 'cover',

        "@media (max-width: 500px)": {
            width: '100%',
            height: '200px',
        }
    },
    imagemCirculo: {
        width: '70%',
        "@media (max-width: 500px)": {
            width: '80%',     
        }
    },
    r: {
        color: '#54B5CF',
        fontFamily: 'Montserrat',
        fontSize: '22px',
        fontWeight: 800,
        textAlign: 'right',
        position: 'relative',
        top: '-5px',
        '@media (max-width: 500px)': {
            fontSize: '20px',
            margin: '250% 0 0 0',
        }
    },
    bntActive: {
        backgroundColor: '#fff',
    },
    bntCurso: {
        textAlign: 'center',
        marginTop: '50px'
    },
    box: {
        display: 'flex',

    },
    emailPerfil: {
        color: '#55B5CF',
        fontFamily: 'Roboto',
        fontSize: '20px',
        fontWeight: 400,
        marginTop: '20px'
    },
    gridCurso: {
        display: 'flex',
        margin: '-10px 20px 0 50px',
        "@media (max-width: 500px)": {
            margin: '0 15px 0 20px',
        }
    },
    gridLink: {
        textDecoration: 'none',
        backgroundColor: '#54B5CF',
        borderRadius: '5px',
        color: '#fff',
        padding: 10,
    },
    imagUpPerfil: {
        color: '#55B5CF',
        fontFamily: 'Roboto',
        fontSize: '22px',
        fontWeight: 400,
        margin: '-25px 0 0 35px',
        textDecoration: 'underline',
        '&:hover': {
            backgroundColor: '#fff',
            fontSize: '23px',
        },
    },
    imagPerfil: {
        objectFit: 'cover!important',
        width: '175px',
        height: '175px',
        marginBottom:'20px',
        "@media (max-width: 500px)": {
            height: '200px',
            margin: '0 50px 0 50px',
            textAlign: 'center',
            width: '200px',
        }
    },
    input: {
        display: 'none',
        padding: '15px'
    },
    imagemCurso: {
        width: '100%',
        padding: '5px 30px 30px 30px',
        "@media (max-width: 500px)": {
            padding: 0,
        }
    },
    linkPerfil: {
        color: '#7ECDE2',
        fontFamily: 'Roboto',
        fontSize: '22px',
        fontWeight: 400,
        textDecoration: 'underline',
        "@media (max-width: 500px)": {
            fontSize: '18px',
        }
    },
    menu: {
        backgroundColor: '#7ECDE2',
         marginBottom: '-5%',
        "@media (max-width: 500px)": {
            marginBottom: '10px'
        }
    },
    newGrid: {
        display: 'flex',
        "@media (max-width: 500px)": {
            display: 'grid'
        }

    },
    p: {
        color: '#666666',
        fontFamily: 'Montserrat',
        fontSize: '20px',
        fontWeight: 500,
        letterSpacing: '0.06em',
        padding: '0px 0px 0px 30px',
        textAlign: 'left',
        '@media (max-width: 500px)': {
            fontSize: '15px',
            padding: '0px 0px 0px 20px',

        }
    },
    page: {
        borderColor: '#54B5CF',
        borderStyle: 'Solid',
        borderWidth: '0 0  2px 0',
        color: '#55B5CF',
        display: 'flex',
        "@media (max-width: 500px)": {

        }

    },
    page2: {
        display: 'flex',
        padding: '48px',
        "@media (max-width: 500px)": {
            display: 'grid',
            padding: '40px 0 0 0',
        }

    },
    pageGeral: {
        padding: '48px',
        "@media (max-width: 500px)": {
            display: 'grid',
            padding: '40px 0 0 0',
        }

    },
    SegCol:{
        margin: '0 0 0 40px ',
        "@media (max-width: 500px)": {
            margin: '20px 0 0 0',
        }
    },
    perfil: {
        padding: '90px 66px 0 85px',
        "@media (max-width: 500px)": {
            padding: '20px',
        }
    },
    
    rowGrid: {
        display: 'flex',
        color: '#666666',
        fontFamily: 'Montserrat',
        fontSize: '20px',
        fontWeight: 500,
        marginTop: '10px',
        "@media (max-width: 500px)": {
            fontSize: '17px',
        }
    },
    subTitulo: {
        color: '#55B5CF',
        fontFamily: 'Roboto',
        fontSize: '25px',
        fontWeight: 600,
        "@media (max-width: 500px)": {
            fontSize: '20px',
        }
    },
    subTituloPerfil: {
        color: '#666666',
        fontFamily: 'Roboto',
        fontSize: '22px',
        fontWeight: 400,
        "@media (max-width: 500px)": {
            fontSize: '17px',
        }
    },
    textoSessao: {
        fontFamily: 'Roboto',
        fontSize: '25px',
        fontWeight: '900',
        paddingTop: '50px',
    },
    typography: {
        borderColor: '#fff',
        borderStyle: 'Solid',
        borderWidth: '0 0  3px 0',
        color: '#fff',
        fontFamily: 'Montserrat',
        fontSize: '25px',
        fontWeight: 800,
        padding: '50px 8% 40px ',
    },
    textoMenu: {
        color: '#54B5CF',
        fontFamily: 'Montserrat',
        fontSize: '18px',
        fontWeight: 800,
        "@media (max-width: 500px)": {
            marginBottom: '0px',
            fontWeight: 700,
        }
    },
    textIcon: {
        textDecoration: 'underline',
        fontFamily: 'Roboto',
        fontSize: 22,
        fontWeight: 400,
        textAlign: 'right',
        marginRight: 70,
        color: '#54B5CF',
        "@media (max-width: 500px)": {
            fontSize: 18,
            marginRight: 0,
        }
    },
    titulo: {
        fontSize: '37px',
        fontFamily: 'Roboto',
        fontWeight: 800,
        "@media (max-width: 500px)": {
            fontSize: '22px',
        }
    },
    textPlanoData: {
        display: 'flex',
        color: '#54B5CF',
        fontFamily: 'Montserrat',
        fontSize: '20px',
        fontWeight: 500,
        "@media (max-width: 500px)": {
            fontSize: '17px',
        }
    },
    textBenef: {
        color: '#666666',
        fontFamily: 'Montserrat',
        fontSize: '20px',
        fontWeight: 500,
        marginTop: '10px',
        "@media (max-width: 500px)": {
            fontSize: '17px',
        }
    },
    textCurso: {
        color: '#666666',
        fontFamily: 'Roboto',
        fontSize: '25px',
        fontWeight: 400,
        "@media (max-width: 500px)": {
            marginTop:'20px',
            fontSize: '17px',
            textAlign: 'center',
        }
    },
    menuNav: {
        alignItems: 'left',
        borderRadius: 0,
        color: '#fff',
        fontFamily: 'Montserrat',
        fontSize: '18px',
        fontWeight: 500,
        justifyContent: 'left',
        padding: '50px 0 50px 12%',
        '&:hover': {
            backgroundColor: '#F8F8FA',
            border: 0,
            color: '#54B5CF',
        },
        '&:focus': {
            borde: 0,
        },
        '@media (max-width: 500px)': {
            padding: '5px 0 5px 20px',
            '&:hover': {
                backgroundColor: '#F8F8FA',
                border: 0,
                color: '#54B5CF',
                '&:focus': {
                    borde: 0,
                },
            },
        }
    },
    linkEditar: {
        color: '#55B5CF',
        textDecoration: 'underline',
        fontFamily: 'Roboto',
        fontSize: 22,
        fontWeight: 400,
        '&:hover': {
            fontWeight: 800,
        },
    
        "@media (max-width: 500px)": {
            fontSize: '18px',
            margin: '0 auto',
        }
    },


    form: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        borderRadius: '23px',
        boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
        padding: '5%',
        "@media (max-width: 500px)": {
            width: '100%',
            padding: '3% 10% 3% 10%',
        }
    },


    formControl: {
        margin: theme.spacing(3),
    },

    imgUp: {
        color: '#55B5CF',
        fontFamily: 'Roboto',
        fontSize: '22px',
        fontWeight: 400,
        margin: '-25px 0 0 35px',
        textDecoration: 'underline',
        '&:hover': {
            backgroundColor: '#fff',
            fontSize: '23px',
        },
    },
    imagem: {
        objectFit: 'cover!important',
        borderRadius: '50%',
        marginBottom:'10px',
        "@media (max-width: 500px)": {
            borderRadius: '100%',
            height: '200px',
            padding: 0,
            margin: '0 50px 0 50px',
            textAlign: 'center',
            width: '200px',
        }
    },
    submit: {
        backgroundColor: '#54B5CF',
        border: 'none', borderRadius: '22px',
        margin: '10% 10px 0',
        fontSize: '28px',
        fontFamily: 'Roboto',
        color:'#fff',
        padding:'5px 50px',
        fontWeight: 400,
        '&:hover': {
			backgroundColor:'#0facc4',
			color:'#fff'
        },
        "@media (max-width: 500px)": {
            fontSize: '18px',
            padding:'5px 20px',

        }
    },

    subtitulo: {
        fontFamily: 'Roboto',
        fontSize: '28px',
        fontWeight: 300,
        color: '#C4C4C4',
        "@media (max-width: 500px)": {
            fontSize: '17px'
        }

    },
    link: {
        textDecoration: 'none',
        color: '#54B5CF',
    },

    typographyTitulo: {
        borderWidth: '0 0  2px 0',
        borderStyle: 'Solid',
        borderColor: '#54B5CF',
        color: '#54B5CF',
        fontFamily: 'Montserrat',
        fontSize: '37px',
        fontWeight: 800,
        padding: '0 0 5px 0',
        margin: '50px 0 10px',
        "@media (max-width: 500px)": {
            fontSize: '20px',
            margin: '0 5px 0 5px 0'
        }
    },
    typographySubtitulo: {
        fontFamily: 'Roboto',
        fontSize: '26px',
        fontWeight: 400,
        margin: '30px 0 0 0',
        color: '#54B5CF',
        "@media (max-width: 500px)": {
            fontSize: '17px',
            margin: '0 5px 0 5px 0'
        }

    },
    voltar: {
        fontFamily: 'Roboto',
        fontSize: '25px',
        fontWeight: '700',
        color: '#54B5CF',
        margin: '50px 0 0 0',
        "@media (max-width: 500px)": {
            margin: '20px 0 -40px 0'
        }
    },
    botaoApp: {
		backgroundColor: '#54B5CF',
		borderColor: '#54B5CF',
		borderRadius: '25px',
		color:'#fff',
		fontFamily: 'Roboto',
		fontSize: '22px',
		fontStyle: 'normal',
		fontWeight: 'normal',
		height: '50px',
		letterSpacing: '0.085em',
		padding: '10px 20px',
		textAlign: 'center',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'none',
			backgroundColor:'#0facc4',
			color:'#fff'
        },
		'@media (max-width: 500px)': {
			backgroundColor: '#54B5CF',
			fontSize: '15px',
			margin:'-30px 0 0 0',
			width: '100%',
		}
	},
    tituloperfil:{
        fontSize: '25px',
        fontFamily: 'Roboto',
        fontWeight: 800,
        "@media (max-width: 500px)": {
            fontSize: '18px',
        }
    },
    inputNew: { 
        font: "inherit",
        color: "currentColor", 
        width: "100%" ,
        border: "0" ,
        height: "1.100em",
        margin: "0" ,
        display: "block" ,
        padding: "6px 0 7px ",
        minWidth: "0" ,
        background: "none" ,
        boxSizing: "content-box ",
        animationName: "mui-auto-fill-cancel" ,
        letterSpacing: "inherit" ,
        animationDuration: "10ms" , 
        borderBottom: "1px solid #a8a8a8",
    }
}));

export default useStyles