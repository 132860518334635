import React from "react";
import { Stack } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { signOut } from "../services/api/AuthService";
import { useHistory } from "react-router-dom";
import Button from "@restart/ui/esm/Button";
import * as PlansServices from "../services/api/PlansServices";

import { useMemo, useState, useEffect, Fragment, useRef } from "react";

import * as ProfessionalsServices from "../services/api/ProfessionalsServices";

const useStyles = makeStyles({
  typography: {
    borderColor: "#fff",
    borderStyle: "Solid",
    borderWidth: "0 0  3px 0",
    color: "#fff",
    fontFamily: "Montserrat",
    fontSize: "25px",
    fontWeight: 800,
    padding: "50px 8% 40px ",
  },

  menuNav: {
    alignItems: "left",
    border: 0,
    borderRadius: 0,
    color: "#fff",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 500,
    justifyContent: "left",
    padding: "60px 0 60px 12%",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#F8F8FA",
      border: 0,
      color: "#54B5CF",
    },
    "&:focus": {
      border: 0,
    },
    "@media (max-width: 500px)": {
      padding: "5px 0 5px 20px",
      "&:hover": {
        backgroundColor: "#F8F8FA",
        border: 0,
        color: "#54B5CF",
        "&:focus": {
          border: 0,
        },
      },
    },
  },
  menuButton: {
    backgroundColor: "#7ECDE2",
    border: 0,
    borderRadius: 0,
    color: "#fff",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 500,
    justifyContent: "left",
    padding: "50px 0 50px 12%",
    textAlign: "left",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#F8F8FA",
      border: 0,
      color: "#54B5CF",
    },
    "&:focus": {
      borde: 0,
    },
    "@media (max-width: 500px)": {
      padding: "5px 0 5px 20px",
      "&:hover": {
        backgroundColor: "#F8F8FA",
        border: 0,
        color: "#54B5CF",
        "&:focus": {
          borde: 0,
        },
      },
    },
  },
});

export default function MenuPerfil() {
  const classes = useStyles();
  const history = useHistory();
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    (async () => {
      const resp = await PlansServices.get();
      setPlan(resp.data[0]);
    })();
  }, []);

  const handleSignOut = async (e) => {
    e.preventDefault();
    await signOut();
    history.push("/admin/dashboard/login");
  };

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    (async () => {
      const resp = await ProfessionalsServices.getProfile();
      console.log(resp.data);
      setProfile(resp.data);
    })();
  }, []);

  return (
    <div>
      <Stack gap={0}>
        <Typography className={classes.typography}>MINHA CONTA</Typography>
        <NavLink
          className={classes.menuNav}
          style={{
            backgroundColor: "#FFFFFF",
            color: "#54B5CF",
            fontWeight: 800,
          }}
          to="/admin/profissional/perfil"
        >
          PERFIL
        </NavLink>

        <NavLink
          className={classes.menuNav}
          style={{
            backgroundColor: "#FFFFFF",
            color: "#54B5CF",
            fontWeight: 800,
          }}
          to="/admin/profissional/assinatura"
        >
          ASSINATURA
        </NavLink>
        {profile?.cpf_cnpj ? (
          <>
        <NavLink
          className={classes.menuNav}
          style={{
            backgroundColor: "#FFFFFF",
            color: "#54B5CF",
            fontWeight: 800,
          }}
          to="/profissionais-supervisor"
        >
          SUPERVISORES
        </NavLink> 
         
            <NavLink
              className={classes.menuNav}
              style={{
                backgroundColor: "#FFFFFF",
                color: "#54B5CF",
                fontWeight: 800,
              }}
              to={
                plan?.plans_id !== null && plan
                  ? "/apps"
                  : "/admin/profissional/assinatura"
              }
            >
              APPS PRÓ-FONO
            </NavLink>

            <NavLink
              className={classes.menuNav}
              style={{
                backgroundColor: "#FFFFFF",
                color: "#54B5CF",
                fontWeight: 800,
              }}
              to={
                plan?.plans_id !== null && plan
                  ? "/admin/profissional/books"
                  : "/admin/profissional/assinatura"
              }
            >
              <i>EBOOKS</i> PRÓ-FONO
            </NavLink>

            <NavLink
              className={classes.menuNav}
              style={{
                backgroundColor: "#FFFFFF",
                color: "#54B5CF",
                fontWeight: 800,
              }}
              to={
                plan?.plans_id !== null && plan
                  ? "/playlists"
                  : "/admin/profissional/assinatura"
              }
            >
              VÍDEOS PRÓ-FONO
            </NavLink>
          </>
        ) : (
          <></>
        )}

        <Button
          className={classes.menuButton}
          style={{
            backgroundColor: "#FFFFFF",
            color: "#54B5CF",
            fontWeight: 800,
          }}
          onClick={(e) => handleSignOut(e)}
        >
          LOGOUT
        </Button>
      </Stack>
    </div>
  );
}
