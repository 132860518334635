import { React, useEffect} from 'react';
import './PDFViewer.css'; // Importe o arquivo CSS que contém as definições de estilo
import { PDFViewer, Viewer } from 'react-view-pdf';    



const PDFViewerPage = () => {  

  useEffect(() => {
    // Intercepta o evento de impressão e o cancela
    window.onbeforeprint = () => {
      console.log('Impressão desabilitada');
      hideContentForPrint();
    };

    // Limpa o manipulador de evento ao desmontar o componente
    return () => {
      window.onbeforeprint = null;
    };
  }, []);

  const hideContentForPrint = () => {
    // Encontra todos os elementos que deseja ocultar durante a impressão
    const elementsToHide = document.querySelectorAll('.print-hidden');
    elementsToHide.forEach((element) => {
      // Remove os elementos do DOM
      element.remove();
    });
  };


   // Manipulador de evento para desabilitar o clique com o botão direito do mouse
   const handleContextMenu = (e) => {
    e.preventDefault();
  };

  // Manipulador de evento para desabilitar a impressão
  const handlePrint = (e) => {
    e.preventDefault();
  };

  // Verificar se o usuário está tentando copiar
  const handleCopy = async (e) => {
    e.preventDefault();
    const text = await navigator.clipboard.readText();
    if (text === '') {
      alert('Captura de tela desabilitada');
    } else {
      alert('Copiado para a área de transferência: ' + text);
    }
  };

  return (
    <div style={{ height: '100%' }} 
        className="print-hidden"
        onContextMenu={handleContextMenu}
        onCopy={handleCopy}
        onPrint={handlePrint}  >  
      <PDFViewer className="print-hidden" url="https://files.minhafono.com.br/testeebook_65e62549.pdf"/>
 
    </div>
    
  );
};

export default PDFViewerPage;
