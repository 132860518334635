import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//IMPORTS ADMIN

import Login from './pages/admin/login';
import Registro from './pages/admin/registro';
import Recupera from './pages/admin/recupera';
import EditarProfissional from './pages/admin/profissional/profissionalEditar';
import Obrigado from './pages/obrigado';

//IMPORTS PROFISSIONAIS 
import Home from './pages/home';
import AssinaturaProfissionais from './pages/assinatura-profissionais';
import ConhecaProfissionais from './pages/conheca-profissionais';
import Cursos from './pages/admin/profissional/cursos';
import Assinatura from './pages/admin/profissional/assinatura';
import Profono from './pages/admin/profissional/profono';
import Perfil from './pages/admin/profissional/perfil'; 
import ProfissionaisCards from './pages/profissionais-cards';
import ProfissionaisSupervisor from './pages/profissionais-supervisor';
import ProfissionalDetalhes from './pages/profissional-detalhes';
import ProfissionalDetalhesSupervisor from './pages/profissional-detalhes-supervisor'; 
import { useUserContext } from './providers/useUserContext';
import PrivateRoute from './pages/private-route';
import Playlists from './pages/playlists';
import Apps from './pages/apps';
import Videos from './pages/videos';
import { Redirect } from 'react-router-dom'; 
import Sobre from './pages/sobre';
import PDFViewerPage from './components/PDFViewer';
import Books from './pages/books';
import BooksReader from './pages/books-reader';


export default function Routes() {
  let user = localStorage.getItem('auth');// useUserContext();    
  
  return (
    <Router>
      <Switch>  
        {/*rota profissional */}
        <Route path='/' exact component={Home} />  
        <Route path='/assinatura-profissionais' exact component={AssinaturaProfissionais} />
        <Route path='/conheca-profissionais' exact component={ConhecaProfissionais} />
        <Route path='/profissionais-cards' exact component={ProfissionaisCards} /> 
        <Route path='/profissionais-supervisor' exact component={ProfissionaisSupervisor} />  
        <Route path='/profissional-detalhes/:id' exact component={ProfissionalDetalhes} />
        <Route path='/profissional-detalhes-supervisor/:id' exact component={ProfissionalDetalhesSupervisor} />
        <Route path='/obrigado' exact component={Obrigado} />
        <Route path='/admin/dashboard/login' exact component={Login} />
        <Route path='/admin/dashboard/registro' exact component={Registro} />
        <Route path='/recupera/registro/:id/:time' exact component={Recupera} />
        <Route path='/sobre' exact component={Sobre} />
        <Route path='/pdf' exact component={PDFViewerPage} /> 
        {/*rota admin */}
        <PrivateRoute user={user}>
          <Route path='/playlists' exact component={Playlists} />
          <Route path='/apps' exact component={Apps} />
          <Route path='/videos' exact component={Videos} />
          <Route path='/admin/profissional/perfil' exact component={Perfil} />
          <Route path='/admin/profissional/cursos' exact component={Cursos} />
          <Route path='/admin/profissional/assinatura' exact component={Assinatura} />
          <Route path='/admin/profissional/profono' exact component={Profono} />
          <Route path='/admin/profissional/profissionalEditar/:id' exact component={EditarProfissional} />
          <Route path='/admin/profissional/books' exact component={Books} /> 
          <Route path='/admin/profissional/read' exact component={BooksReader} /> 
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
