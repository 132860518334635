import axios from 'axios';
import { baseURL } from './backend';

const setAuth = (response) => {
  if (response.data) {
    localStorage.setItem('auth', JSON.stringify(response.data));
  }

  return response.data;
}; 

const headers = {
  'Content-Type': 'application/json;charset=UTF-8'
};

export const create = (data) => axios.post(`${baseURL}/backoffice/professionals`, data);

export const signIn = (credentials) => axios.post(`${baseURL}/front/secured/auth/login`, credentials).then(setAuth);

export const signInWithKey = (data) => axios.post(`https://app.profono.com.br/api/validate/licence`, JSON.stringify(data), { headers });

export const refreshToken = (refreshToken) =>
  axios
    .post(`${baseURL}/front/secured/auth/refresh-token`, {
      refreshToken,
    })
    .then(setAuth);

export const signOut = () => {
  localStorage.removeItem('auth');
};

export const getCurrentUser = async () => {
  const authJson = await localStorage.getItem('auth');
  if (authJson) {
    return JSON.parse(authJson);
  }

  return null;
};
