import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({ user, children, ...rest }) {

/*   if (!user) return null;
  if (user === undefined) return null;   */ 
  return (
    <Route
      {...rest}
      render={({ location }) =>
      user !== undefined && user !== null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/admin/dashboard/login',
            }}
          />
        )
      }
    />
  );
}
export default PrivateRoute;
