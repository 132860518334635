import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Player,
  BigPlayButton,
  ControlBar,
  PlaybackRateMenuButton,
  LoadingSpinner,
} from "video-react";
import Button from '@restart/ui/esm/Button';

import Header from "../../components/header";
import Footer from "../../components/footer";

import * as PlaylistsServices from "../../services/api/PlaylistsServices";
import * as VideosServices from "../../services/api/VideosServices";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Paper from "@mui/material/Paper";
import CardMedia from "@mui/material/CardMedia";
import LinearProgress from '@mui/material/LinearProgress';

import HeaderVideos from "../../components/headerVideos";

import ReplyIcon from '@mui/icons-material/Reply';

import { NavLink } from "react-router-dom";

const useStyles = makeStyles({
  botaoPrimeiro: {
    backgroundColor: '#54B5CF',
    borderColor: '#54B5CF',
    borderRadius: '25px',
    color: '#fff',
    float: 'right',
    fontFamily: 'Roboto',
    fontSize: '22px',
    fontStyle: 'bold',
    fontWeight: 'bold',
    height: '50px',
    margin: '40px 0 0 0',
    padding: '10px 60px 20px 60px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#0facc4',
      color: '#fff'
    },
    '@media (max-width: 500px)': {
      backgroundColor: '#54B5CF',
      fontSize: '20px',
      padding: '10px 20px 0 20px',
      margin: '10px 0 0 0'

    }
  },
  view: {
    backgroundColor: '#54b5cf',
    borderRadius: '20px',
    padding: '10px',
    color: 'white'
  },
});

export default function Videos() {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [playlists, setPlaylists] = React.useState([]);
  const [video, setVideo] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  //url 
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const playlistId = urlParams.get('p')
  const videoSlug = urlParams.get('v')


  React.useEffect(() => {
    (async () => {
      const resp = await PlaylistsServices.findById(playlistId);
      setPlaylists(resp.data);
      const getVideo = await VideosServices.findBySlugName(videoSlug);
      setVideo(getVideo.data)
      setLoading(false)
    })();
  }, [page]);


  return (
    <div className={classes.container} style={{ background: "#c0c0c01f" }}>
      {
        loading ? (
          <Box sx={{ width: '100%' }} >
            <LinearProgress color={"error"} />
          </Box>
        ) : <></>
      }
      <HeaderVideos />

      <Grid
        container
        spacing={4}
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        style={{ padding: "20px" }}
      >
        <Grid item xs={12}  md={7}>
          {
            video.length !== 0 ? (
              video?.map((v, index) => (
                <>
                  <Player
                    height={10}
                    playsInline
                    poster={v?.thumbnail}
                    src={v?.path}
                  >
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                    <ControlBar>
                      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    </ControlBar>
                  </Player>

                  <Typography variant="h6" sx={{ margin: '15px' }}>
                    {v?.title}
                  </Typography>
                  <Divider variant="inset" />
                  <Typography sx={{ mx: 2 }}>
                    <small dangerouslySetInnerHTML={{ __html: v?.description }} />
                  </Typography>
                </>
              ))
            ) :
              (
                playlists?.map((v, index) => (
                <>
                  <Player
                    height={10}
                    playsInline
                    poster={v?.thumbnail}
                    src={v?.path}
                  >
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                    <ControlBar>
                      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    </ControlBar>
                  </Player> 
                </>
              ))
              )

          }

        </Grid>
        <Grid item xs={12}  md={5}>

          {playlists?.map((value, index) => (
            <Paper elevation={0} square>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <Box sx={{ mx: 2 }}>
                  <Grid container alignItems="center">
                    <Grid item xs sx={{ mt: 2 }}>
                      <Typography gutterBottom variant="h6" component="div">
                        {value?.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography color="text.secondary" variant="body2">
                    <small>{value?.description}</small>
                  </Typography>
                </Box>
                <Box sx={{ mt: 0, ml: 1, mb: 1 }}>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    {value?.videosPlaylists.map((v, index) => (
                      <>
                        {v?.videos.status == "Público" ? (
                          <a style={{ color: "black", textDecoration: "none" }} href={`/videos?v=${v?.videos.slug_name}&p=${playlistId}`} >
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start" style={{ cursor: "pointer" }} className={v?.videos.slug_name === videoSlug ? classes.view : null}>
                              <ListItemAvatar>
                                <CardMedia
                                  style={{ marginRight: "10px" }}
                                  component="img"
                                  sx={{ width: 80 }}
                                  image={v?.videos.thumbnail}
                                  alt="Live from space album cover"
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={v?.videos.title}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                    </Typography>
                                    <small>
                                      {v?.videos?.description?.length <= 25 && <span dangerouslySetInnerHTML={{ __html: v?.videos?.description }} />
                                      }
                                      {v?.videos?.description?.length > 200 && <span title={v?.videos?.description}>

                                        <span dangerouslySetInnerHTML={{ __html: v?.videos?.description.substr(0, 200) }} />
                                      </span>}
                                    </small>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          </a>
                        ) : (
                          <></>
                        )}


                      </>
                    ))}
                  </List>
                </Box>
              </Box>
            </Paper>
          ))}

          <Button className={classes.botaoPrimeiro} >
            <NavLink
              style={{
                color: "#fff",
                textDecoration: "none"
              }}
              to="/playlists"
            >
              VOLTAR
            </NavLink>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
